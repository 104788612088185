export const getImageUrl = ({ url, crop, width, height }) => {
  if (!url) {
    return
  }
  const params = {
    url
  }
  if (crop) {
    const c = []
    c.push(crop.minX)
    c.push(crop.minY)
    c.push(crop.maxX - crop.minX)
    c.push(crop.maxY - crop.minY)
    params.crop = c.join(',')
  }
  if (width) {
    params.width = width
  }
  if (height) {
    params.height = height
  }
  return `${process.env.CROPPER_URL}/?${new URLSearchParams(params)}`
}

import { h } from 'preact'
import { Block, Col, Row } from 'jsxstyle/preact'
import {
  Avatar,
  Button,
  EditIcon,
  FloatingSheet,
  IconButton,
  P,
  SpacerHorizontal,
  SpacerVertical
} from '@sodra/bongo-ui'

import { getImageUrl } from './get-image-url'

export const UserSheet = ({ user, position, onClose, onEditAvatar }) => {
  if (!user) {
    return
  }

  return (
    <FloatingSheet
      onClose={onClose}
      positionElement={position.positionElement}
      position={position.position}
    >
      <Col alignItems="center" padding="30px">
        <Block position="relative">
          <Avatar
            huge
            src={getImageUrl({ ...user.picture, width: 160, height: 160 })}
            name={user.name}
          />
          <IconButton
            small
            outlined
            backgroundColor="var(--surface)"
            color="var(--on-surface)"
            icon={EditIcon}
            position="absolute"
            right="0"
            bottom="0"
            onClick={onEditAvatar}
          />
        </Block>
        <SpacerVertical />
        <P fontWeight="bold">{user.name}</P>
        <P>{user.email}</P>
        <SpacerVertical />
        <Button text tooltipText="NOTE: Doesn’t do anything">
          Log out
        </Button>
      </Col>
    </FloatingSheet>
  )
}

import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block, Col } from 'jsxstyle/preact'
import {
  BackArrowIcon,
  CloseIcon,
  DeleteIcon,
  Dialog,
  EditIcon,
  SpacerVertical
} from '@sodra/bongo-ui'
import { ImageCropper } from '@sodra/image-cropper'
import { useMedia } from '@sodra/use-media'

import { getImageUrl } from './get-image-url'
import { UploadImage } from './UploadImage'

export const EditAvatar = ({ user, onUpdate, onRemove, onClose }) => {
  const isNarrow = useMedia(['(max-width: 600px)'], [true], false)
  const [step, setStep] = useState('show') // show, upload, crop
  const [url, setUrl] = useState() // The URL of the uploaded image
  const [crop, setCrop] = useState()

  let title
  let titleIcon
  let onTitleIconClick
  let actions

  if (step === 'show') {
    title = 'Profile picture'
    titleIcon = CloseIcon
    onTitleIconClick = onClose
    actions = [
      { text: 'Edit', icon: EditIcon, onClick: () => setStep('upload') },
      { text: 'Remove', icon: DeleteIcon, onClick: () => onRemove() }
    ]
  } else if (step === 'upload') {
    title = 'Change profile picture'
    titleIcon = BackArrowIcon
    onTitleIconClick = () => setStep('show')
  } else if (step === 'crop') {
    title = 'Crop'
    titleIcon = BackArrowIcon
    onTitleIconClick = () => setStep('upload')
    actions = [
      {
        text: 'Save',
        onClick: () => {
          onUpdate({ url, crop })
        }
      },
      { text: 'Cancel', onClick: onClose }
    ]
  }

  const handleImageUploaded = (url) => {
    setUrl(url)
    setStep('crop')
  }

  return (
    <Dialog
      title={title}
      titleIcon={titleIcon}
      onTitleIconClick={onTitleIconClick}
      onClose={onClose}
      actions={actions}
      large={step === 'upload' || step === 'crop'}
      full={isNarrow}
      overflow={step === 'crop'}
      disableAutofocus
    >
      {step === 'show' && (
        <Col alignItems="center">
          <SpacerVertical />
          {user.picture && (
            <Block
              component="img"
              width="240"
              height="240"
              borderRadius="50%"
              backgroundColor="var(--container-background)"
              props={{
                src: getImageUrl({ ...user.picture, width: 240, height: 240 })
              }}
            />
          )}
          {!user.picture && (
            <Block
              width="240"
              height="240"
              borderRadius="50%"
              backgroundColor="var(--container-background)"
            />
          )}
          <SpacerVertical />
        </Col>
      )}
      {step === 'upload' && <UploadImage onImageUploaded={handleImageUploaded} />}
      {step === 'crop' && (
        <ImageCropper
          url={url}
          aspectRatio={1}
          useCircularMask
          margin={isNarrow ? 50 : 100}
          onChange={setCrop}
          flex="1"
          height="100%"
          width="100%"
        />
      )}
    </Dialog>
  )
}

import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'

import { Button, Form, Select, SpacerHorizontal, SpacerVertical, TextField } from '@sodra/bongo-ui'

import daniel from './daniel.png'
import jacobellis from './jacobellis.jpeg'
import harlaut from './harlaut.jpeg'
import monkey from './monkey.jpeg'

import { ImageCropper } from '@sodra/image-cropper'

const imageOptions = [
  { value: daniel, text: 'Daniel' },
  { value: jacobellis, text: 'Jacobellis' },
  { value: harlaut, text: 'Harlaut' },
  { value: monkey, text: 'Monkey' }
]

const aspectRatioOptions = [
  { value: undefined, text: 'None' },
  { value: 1, text: '1:1' },
  { value: 4 / 3, text: '4:3' },
  { value: 2 / 3, text: '2:3' },
  { value: 16 / 9, text: '16:9' },
  { value: 16 / 10, text: '16:10' },
  { value: 1.91 / 1, text: '1.91:1' }
]

const marginOptions = [
  { value: 50, text: '50 px' },
  { value: 100, text: '100 px' },
  { value: 150, text: '150 px' }
]

const CropForm = ({
  x: iX,
  y: iY,
  width: iWidth,
  height: iHeight,
  aspectRatio,
  imageSize,
  onChange
}) => {
  const [x, setX] = useState(iX)
  const [y, setY] = useState(iY)
  const [width, setWidth] = useState(iWidth)
  const [height, setHeight] = useState(iHeight)

  const getMaxWidth = () => {
    if (imageSize) {
      if (!aspectRatio) {
        return imageSize.width
      }
      const imageAspectRatio = imageSize.width / imageSize.height
      if (aspectRatio > imageAspectRatio) {
        return imageSize.width
      } else {
        return Math.round(imageSize.height * aspectRatio)
      }
    }
  }

  const getMaxHeight = () => {
    if (imageSize) {
      if (!aspectRatio) {
        return imageSize.height
      }
      const imageAspectRatio = imageSize.width / imageSize.height
      if (aspectRatio > imageAspectRatio) {
        return Math.round(imageSize.width / aspectRatio)
      } else {
        return imageSize.height
      }
    }
  }

  useEffect(() => {
    setX(iX)
    setY(iY)
    setWidth(iWidth)
    setHeight(iHeight)
  }, [iX, iY, iWidth, iHeight])

  const validateX = () => {
    if (imageSize) {
      let newX = x
      let newWidth
      let newHeight
      if (newX < 0) {
        newX = 0
      }
      if (newX > imageSize.width - 50) {
        newX = imageSize.width - 50
      }
      if (newX + width > imageSize.width) {
        newWidth = imageSize.width - newX
      }
      if (aspectRatio && newWidth) {
        newHeight = Math.round(newWidth / aspectRatio)
      }
      if (newX !== x) {
        setX(newX)
      }
      if (newWidth) {
        setWidth(newWidth)
      }
      if (newHeight) {
        setHeight(newHeight)
      }
    }
  }

  const validateY = () => {
    if (imageSize) {
      let newY = y
      let newWidth
      let newHeight
      if (newY < 0) {
        newY = 0
      }
      if (newY > imageSize.height - 50) {
        newY = imageSize.height - 50
      }
      if (newY + height > imageSize.height) {
        newHeight = imageSize.height - newY
      }
      if (aspectRatio && newHeight) {
        newWidth = Math.round(newHeight * aspectRatio)
      }
      if (newY !== y) {
        setY(newY)
      }
      if (newWidth) {
        setWidth(newWidth)
      }
      if (newHeight) {
        setHeight(newHeight)
      }
    }
  }

  const validateWidth = () => {
    if (imageSize) {
      const minWidth = 50
      const maxWidth = getMaxWidth()
      let newWidth = width
      let newHeight
      let newX
      if (newWidth < minWidth) {
        newWidth = minWidth
      }
      if (newWidth > maxWidth) {
        newWidth = maxWidth
      }
      if (newWidth + x > imageSize.width) {
        newX = imageSize.width - newWidth
      }
      if (aspectRatio) {
        newHeight = Math.round(newWidth / aspectRatio)
      }
      if (newWidth !== width) {
        setWidth(newWidth)
      }
      if (newHeight) {
        setHeight(newHeight)
      }
      if (newX >= 0) {
        setX(newX)
      }
    }
  }

  const validateHeight = () => {
    if (imageSize) {
      const minHeight = 50
      const maxHeight = getMaxHeight()
      let newHeight = height
      let newWidth
      let newY
      if (newHeight < minHeight) {
        newHeight = minHeight
      }
      if (newHeight > maxHeight) {
        newHeight = maxHeight
      }
      if (newHeight + y > imageSize.height) {
        newY = imageSize.height - newHeight
      }
      if (aspectRatio) {
        newWidth = Math.round(newHeight * aspectRatio)
      }
      if (newHeight !== height) {
        setHeight(newHeight)
      }
      if (newWidth >= 0) {
        setWidth(newWidth)
      }
      if (newY >= 0) {
        setY(newY)
      }
    }
  }

  const handleSubmit = () => {
    onChange({ x, y, width, height })
  }

  const parse = (v) => {
    if (v === undefined || v === '') {
      return 0
    }
    return parseFloat(v)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row alignItems="center">
        <TextField
          type="number"
          label="x"
          value={x}
          onChange={(v) => setX(parse(v))}
          onBlur={validateX}
          width="100px"
          disabled={!imageSize}
        />
        <SpacerHorizontal />
        <TextField
          type="number"
          label="y"
          value={y}
          onChange={(v) => setY(parse(v))}
          onBlur={validateY}
          width="100px"
          disabled={!imageSize}
        />
        <SpacerHorizontal />
        <TextField
          type="number"
          label="width"
          value={width}
          onChange={(v) => setWidth(parse(v))}
          onBlur={validateWidth}
          width="100px"
          disabled={!imageSize}
        />
        <SpacerHorizontal />
        <TextField
          type="number"
          label="height"
          value={height}
          onChange={(v) => setHeight(parse(v))}
          onBlur={validateHeight}
          width="100px"
          disabled={!imageSize}
        />
        <SpacerHorizontal />
        <Button onClick={handleSubmit} disabled={!imageSize}>
          Set crop
        </Button>
      </Row>
    </Form>
  )
}

const getCrop = ({ x, y, width, height }) => {
  if (x === undefined || y === undefined || width === undefined || height === undefined) {
    return
  }
  if (x < 0 || y < 0 || width < 0 || height < 0) {
    return
  }
  const crop = {
    minX: x,
    minY: y,
    maxX: x + width,
    maxY: y + height
  }
  return crop
}

export const Test1 = () => {
  const [image, setImage] = useState(daniel)
  const [imageSize, setImageSize] = useState()
  const [margin, setMargin] = useState(marginOptions[1].value)
  const [x, setX] = useState()
  const [y, setY] = useState()
  const [width, setWidth] = useState()
  const [height, setHeight] = useState()
  const [aspectRatio, setAspectRatio] = useState(undefined)

  const resetCrop = () => {
    setX()
    setY()
    setWidth()
    setHeight()
  }

  useEffect(() => {
    resetCrop()
  }, [aspectRatio])

  useEffect(() => {
    setImageSize()
    resetCrop()
    if (image) {
      const img = new Image()
      const handleOnLoad = (e) => {
        setImageSize({
          width: img.width,
          height: img.height
        })
      }
      img.addEventListener('load', handleOnLoad)
      img.src = image
      return () => {
        img.removeEventListener('load', handleOnLoad)
      }
    }
  }, [image])

  const handleCropChange = (crop) => {
    if (crop) {
      let x = crop.minX
      let y = crop.minY
      let width = crop.maxX - crop.minX
      let height = crop.maxY - crop.minY
      if (aspectRatio) {
        const cropAspectRatio = width / height
        if (cropAspectRatio > aspectRatio) {
          width = Math.round(height * aspectRatio)
        } else {
          height = Math.round(width / aspectRatio)
        }
      }
      setX(x)
      setY(y)
      setWidth(width)
      setHeight(height)
    }
  }

  const handleCropFormChange = (crop) => {
    if (crop) {
      let x = crop.x
      let y = crop.y
      let width = crop.width
      let height = crop.height
      if (aspectRatio) {
        const cropAspectRatio = width / height
        if (cropAspectRatio > aspectRatio) {
          width = Math.round(height * aspectRatio)
        } else {
          height = Math.round(width / aspectRatio)
        }
      }
      setX(x)
      setY(y)
      setWidth(width)
      setHeight(height)
    }
  }

  const crop = getCrop({ x, y, width, height })

  return (
    <Col height="100%">
      <Block margin="20px">
        <Row>
          <Select
            label="Image"
            options={imageOptions}
            value={image}
            onChange={setImage}
            minWidth="150px"
          />
          <SpacerHorizontal />
          <Select
            label="Aspect ratio"
            options={aspectRatioOptions}
            value={aspectRatio}
            onChange={setAspectRatio}
            minWidth="150px"
          />
          <SpacerHorizontal />
          <Select
            label="Margin"
            options={marginOptions}
            value={margin}
            onChange={setMargin}
            minWidth="150px"
          />
        </Row>
        <SpacerVertical />
        <Row>
          <CropForm
            x={x}
            y={y}
            width={width}
            height={height}
            aspectRatio={aspectRatio}
            imageSize={imageSize}
            onChange={handleCropFormChange}
          />
        </Row>
      </Block>
      <ImageCropper
        url={image}
        crop={crop}
        aspectRatio={aspectRatio}
        useCircularMask={aspectRatio === 1}
        margin={margin}
        onChange={handleCropChange}
        flex="1"
      />
    </Col>
  )
}

import { Fragment, h } from 'preact'
import { Block } from 'jsxstyle/preact'

import { Link } from '@sodra/bongo-ui'
import { Route, Router, routeTo, Switch } from '@sodra/prutt'
import { Test1 } from './Test1.js'
import { Test2 } from './Test2.js'
import { Test3 } from './Test3/index.js'

const App = () => {
  return (
    <Fragment>
      <Router>
        <Switch>
          <Route path="/test1" component={Test1} />
          <Route path="/test2" component={Test2} />
          <Route path="/test3" component={Test3} />
          <Block>
            <Link to="" onClick={() => routeTo('/test1')} padding="5px">
              Test 1
            </Link>
            <Link to="" onClick={() => routeTo('/test2')} padding="5px">
              Test 2
            </Link>
            <Link to="" onClick={() => routeTo('/test3')} padding="5px">
              Test 3
            </Link>
          </Block>
        </Switch>
      </Router>
    </Fragment>
  )
}

export default App

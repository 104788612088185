import { h, Fragment, cloneElement } from 'preact'
import { useEffect, useState, useRef } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'
import { Button } from './Button'
import { IconButton } from './IconButton'
import { Highlight } from './highlights'
import { useKeyPressedHighlight } from './use-key-pressed-highlight'
import { Ripples } from './Ripples'
import { ProgressCircular } from './ProgressCircular'
import { ErrorIcon, CheckmarkIcon } from './icons'
import { SpacerHorizontal } from './spacers'
import { Tooltip } from './Tooltip'

const Checkmark = ({ checked, disabled, ...style }) => (
  <Block
    height="20px"
    width="20px"
    borderRadius="3px"
    position="relative"
    opacity={disabled ? 0.33 : 1}
    transition="opacity 0.18s cubic-bezier(0, 0, .2, 1)"
    style={{ ...style }}
  >
    <Block
      boxSizing="border-box"
      position="absolute"
      width="100%"
      height="100%"
      border="2px solid var(--selection-control)"
    />
    <Block
      position="absolute"
      width="100%"
      height="100%"
      transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
      opacity={checked ? 1 : 0}
      backgroundColor={'var(--accent-surface)'}
    >
      <CheckmarkIcon fill="var(--on-selection-control)" size={20} marginTop="-1px" />
    </Block>
  </Block>
)

export const ListItem = ({
  icon: Icon,
  checkboxVisible,
  checkboxMode = 'primary',
  checked,
  visual,
  text,
  preventTextWrap = false,
  secondaryText,
  metaText,
  tooltipText,
  actionText,
  actionIcon,
  actionTooltipText,
  actionDisabled,
  actionLoading,
  roundedCorners = false,
  focusable = false,
  loading = false,
  error = false,
  active = false,
  disabled = false,
  focusSelf,
  focusNext,
  focusPrevious,
  onFocus,
  listOnBlur,
  onBlur,
  onHover,
  onClick,
  delayClick = false,
  onActionClick,
  leftWidth = '55px',
  selectOnSpacePressed = true,
  props
}) => {
  const listItem = useRef()

  /* Border radius used when rendering as focused och clicked/tapped */
  const borderRadius = roundedCorners ? '3px' : '0'

  const [isUsingTouchTimeout, setIsUsingTouchTimeout] = useState(undefined)
  const [isUsingMouse, setIsUsingMouse] = useState(false)
  const [isUsingTouch, setIsUsingTouch] = useState(false)
  const [hover, setHover] = useState(false)
  const [showFocus, setShowFocus] = useState(false)
  const [keyPressedHighlights, addKeyPressedHighlight] = useKeyPressedHighlight({ borderRadius })

  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const handleClick = (e) => {
    e && e.preventDefault()
    focusSelf && focusSelf()
    setTimeout(
      () => {
        if (onClick) {
          onClick({ type: 'pointer', x: e.clientX, y: e.clientY, target: e.target })
        }
        // Remove focus after clicking/tapping
        // Note: maybe not needed. We already try to not
        // setShowFocus(true) in handleFocus
        setShowFocus(false) // Remove focus after clicking/tapping
      },
      delayClick ? 180 : 0
    )
  }

  const handleActionClick = (e) => {
    e && e.preventDefault && e.preventDefault()
    if (onActionClick) {
      onActionClick(e)
    }
  }

  const handleMouseEnter = (e) => {
    setHover(true)
    if (onHover) {
      onHover(true)
    }
  }

  const handleMouseLeave = () => {
    setHover(false)
    if (onHover) {
      onHover(false)
    }
  }

  const handleMouseDown = (e) => setIsUsingMouse(true)

  const handleMouseUp = (e) => setIsUsingMouse(false)

  const handleTouchStart = (e) => {
    if (isUsingTouchTimeout) {
      clearTimeout(isUsingTouchTimeout)
    }
    setIsUsingTouch(true)
  }

  const handleTouchEnd = (e) => {
    if (isUsingTouchTimeout) {
      clearTimeout(isUsingTouchTimeout)
    }
    setIsUsingTouchTimeout(setTimeout(() => setIsUsingTouch(false), 100))
  }

  const handleFocus = (e) => {
    // Skip showing focus if mouse or touch is used
    if (!isUsingMouse && !isUsingTouch) {
      setShowFocus(true)
    }
    onFocus && onFocus()
  }

  const handleBlur = (e) => {
    listOnBlur && listOnBlur()
    onBlur && onBlur()
    setShowFocus(false)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || (selectOnSpacePressed && e.keyCode === 32)) {
      // Enter, space
      e.preventDefault()
      e.stopPropagation()
      if (!disabled) {
        addKeyPressedHighlight()
      }
      if (onClick && !disabled) {
        onClick({ type: 'keyboard', target: listItem.current })
      }
    } else if (e.keyCode === 40 || e.keyCode === 39) {
      // Down arrow, right arrow
      e.preventDefault()
      e.stopPropagation()
      focusNext()
    } else if (e.keyCode === 38 || e.keyCode === 37) {
      // Up arrow, left arrow
      e.preventDefault()
      e.stopPropagation()
      focusPrevious()
    }
  }

  const padding = actionIcon || actionText ? '12px 5px 12px 20px' : '12px 20px'

  let actionButton
  if (!loading && !error) {
    if (actionText) {
      actionButton = (
        <Button
          outlined
          icon={actionIcon}
          tooltipText={actionTooltipText}
          color={active ? 'var(--accent-text)' : 'var(--on-surface-light)'}
          focusable={focusable}
          onClick={handleActionClick}
          disabled={disabled || actionDisabled}
          loading={actionLoading}
        >
          {actionText}
        </Button>
      )
    } else if (actionIcon) {
      actionButton = (
        <IconButton
          text
          icon={actionIcon}
          tooltipText={actionTooltipText}
          color={active ? 'var(--accent)' : 'var(--on-surface-light)'}
          focusable={focusable}
          onClick={handleActionClick}
          disabled={disabled || actionDisabled}
          loading={actionLoading}
        />
      )
    }
  }

  useEffect(() => {
    const e = listItem.current
    if (e) {
      e.addEventListener('touchstart', handleTouchStart, { passive: true })
      e.addEventListener('touchend', handleTouchEnd, { passive: true })
      return () => {
        e.removeEventListener('touchstart', handleTouchStart)
        e.removeEventListener('touchend', handleTouchEnd)
      }
    }
  }, [])

  return (
    <Block
      component="li"
      class="bui-show-keyboard-focus-inside"
      position="relative"
      outline="none"
      cursor={!disabled ? 'pointer' : 'not-allowed'}
      whiteSpace={preventTextWrap ? 'nowrap' : undefined}
      props={{
        ref: listItem,
        onMouseDown: supportsHover ? handleMouseDown : null,
        onMouseUp: supportsHover ? handleMouseUp : null,
        onMouseEnter: supportsHover ? handleMouseEnter : null,
        onMouseLeave: supportsHover ? handleMouseLeave : null,
        onFocus: handleFocus,
        onBlur: handleBlur,
        tabIndex: focusable ? 0 : -1,
        onClick: !disabled ? handleClick : null,
        onKeyDown: handleKeyDown,
        role: 'listitem',
        ...props
      }}
    >
      {!disabled && (hover || showFocus) && (
        <Highlight borderRadius={borderRadius} opacity={0.15} />
      )}
      {!disabled && keyPressedHighlights}
      <Ripples borderRadius={borderRadius} disabled={disabled} />
      <Row padding={padding} alignItems="center">
        {checkboxVisible && checkboxMode === 'primary' && (
          <Block width={leftWidth} pointerEvents="none">
            <Checkmark checked={checked} disabled={disabled} />
          </Block>
        )}
        {Icon && (
          <Block width={leftWidth}>
            <Icon
              fill={
                disabled
                  ? 'var(--on-surface-lighter)'
                  : active
                  ? 'var(--accent)'
                  : 'var(--on-surface-light)'
              }
            />
          </Block>
        )}
        {visual && <Block width={leftWidth}>{cloneElement(visual, { disabled: disabled })}</Block>}
        <Col flex="1" overflow="hidden">
          <Block
            flex="1"
            color={disabled ? 'var(--on-surface-lighter)' : active ? 'var(--accent-text)' : ''}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {text}
          </Block>
          {secondaryText && (
            <Block
              marginTop="5px"
              fontSize="14px"
              lineHeight="16px"
              color={disabled ? 'var(--on-surface-lighter)' : 'var(--on-surface-light)'}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {secondaryText}
            </Block>
          )}
        </Col>
        {metaText && (
          <Fragment>
            <SpacerHorizontal small />
            <Block
              textAlign="right"
              fontSize="14px"
              lineHeight="16px"
              color={disabled ? 'var(--on-surface-lighter)' : 'var(--on-surface-light)'}
            >
              {metaText}
            </Block>
          </Fragment>
        )}
        {checkboxVisible && checkboxMode === 'secondary' && (
          <Checkmark checked={checked} disabled={disabled} pointerEvents="none" />
        )}
        {loading && (
          <Block width="40px" textAlign="center">
            <ProgressCircular size="24" color="var(--on-surface-light)" />
          </Block>
        )}
        {error && (
          <Block width="40px" textAlign="center">
            <ErrorIcon fill="var(--error)" />
          </Block>
        )}
        {actionButton}
        {tooltipText && (
          <Tooltip visible={showFocus || hover} generator={listItem} text={tooltipText} />
        )}
      </Row>
    </Block>
  )
}

import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'

import {
  AppsIcon,
  Avatar,
  IconButton,
  Link,
  SpacerHorizontal,
  SpacerVertical
} from '@sodra/bongo-ui'

import { UserSheet } from './UserSheet'
import { getImageUrl } from './get-image-url'
import { EditAvatar } from './EditAvatar'

export const Test3 = () => {
  const [user, setUser] = useState({
    name: 'Silly Me',
    email: 'sillyme@gmail.com',
    picture: {
      url: 'https://image.posterlounge.se/images/big/1868016.jpg',
      crop: { minX: 0, minY: 47, maxX: 334, maxY: 334 }
    }
  })
  const [userSheetPosition, setUserSheetPosition] = useState()
  const [editAvatarVisible, setEditAvatarVisible] = useState()

  const picture = user.picture ? user.picture : undefined

  return (
    <Col minHeight="100%">
      <Row alignItems="center" justifyContent="space-between" margin="10px 20px">
        <Link to="/">Image Cropper</Link>
        <Row alignItems="center">
          <Link to="https://mail.google.com">Gmail</Link>
          <SpacerHorizontal />
          <Link to="https://images.google.com">Images</Link>
          <SpacerHorizontal />
          <IconButton icon={AppsIcon} tooltipText="No more apps for you :-(" />
          <SpacerHorizontal />
          <Avatar
            src={getImageUrl({ ...picture, width: 80, height: 80 })}
            name={user.name}
            tooltipText={`${user.name} <${user.email}>`}
            onClick={({ type, x, y, target }) => {
              if (!userSheetPosition) {
                if (type === 'keyboard') {
                  setUserSheetPosition({ positionElement: target })
                } else {
                  setUserSheetPosition({ position: { x, y } })
                }
              }
            }}
          />
        </Row>
      </Row>
      <Col alignItems="center" flex="1">
        <Col justifyContent="flex-end" minHeight="100px" maxHeight="250px" flex="1">
          <Block color="var(--container-background)" fontSize="72px">
            Google
          </Block>
        </Col>
        <SpacerVertical />
        <Block
          outline="1px solid var(--container-outline)"
          height="50px"
          borderRadius="25px"
          width="80%"
          maxWidth="450px"
        />
      </Col>
      <Block flex="1" minHeight="100px" />
      <Block background="var(--container-background)" height="150px" width="100%" />
      {userSheetPosition && (
        <UserSheet
          user={user}
          position={userSheetPosition}
          onClose={() => setUserSheetPosition()}
          onEditAvatar={() => {
            setEditAvatarVisible(true)
            setUserSheetPosition()
          }}
        />
      )}
      {editAvatarVisible && (
        <EditAvatar
          user={user}
          onClose={() => setEditAvatarVisible(false)}
          onUpdate={({ url, crop }) => {
            setUser((user) => ({
              ...user,
              picture: {
                url,
                crop
              }
            }))
            setEditAvatarVisible(false)
          }}
          onRemove={() => {
            setUser((user) => ({
              name: user.name
            }))
            setEditAvatarVisible(false)
          }}
        />
      )}
    </Col>
  )
}

import { h, Fragment, cloneElement } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { Col } from 'jsxstyle/preact'

const useDidMount = () => {
  const [didMount, setDidMount] = useState(false)
  useEffect(() => setDidMount(true), [])
  return didMount
}

/*
  autofocus - gives focus to the List automatically when being mounted
  delayClick - wait for ripple animation before triggering click
*/
export const List = ({
  children,
  divider,
  initialFocusIndex = 0,
  roundedCorners = false,
  autofocus,
  delayClick,
  onListItemFocusChange,
  roving = true,
  selectOnSpacePressed = true,
  props,
  ...style
}) => {
  const didMount = useDidMount()
  const [hasFocus, setHasFocus] = useState(autofocus)
  const [focusIndex, setFocusIndex] = useState(initialFocusIndex)
  const focusableItem = useRef()
  const prevChildren = useRef()

  const childrenArray = Array.isArray(children) ? children : [children]

  const items = childrenArray
    .reduce((acc, val) => acc.concat(val), [])
    .filter((item) => item !== null && !!item)

  const focus = (index) => {
    setHasFocus(true)
    setFocusIndex(index)
  }

  const focusNext = () => {
    if (focusIndex < items.length - 1) {
      setHasFocus(true)
      setFocusIndex(focusIndex + 1)
    } else if (roving && focusIndex === items.length - 1) {
      setHasFocus(true)
      setFocusIndex(0)
    }
  }

  const focusPrevious = () => {
    if (focusIndex > 0) {
      setHasFocus(true)
      setFocusIndex(focusIndex - 1)
    } else if (roving && focusIndex === 0) {
      setHasFocus(true)
      setFocusIndex(items.length - 1)
    }
  }

  useEffect(() => {
    if (didMount) {
      focus(initialFocusIndex)
    }
  }, [initialFocusIndex])

  useEffect(() => {
    // Move focus when focusIndex updates
    // Don’t give focus if component has not finished mounting
    if (didMount && hasFocus && focusableItem && focusableItem.current) {
      focusableItem.current.base.focus()
    }
    onListItemFocusChange && onListItemFocusChange(focusIndex)
  }, [focusIndex, hasFocus])

  useEffect(() => {
    let newItems = true

    const childrenArray = Array.isArray(children) ? children : [children]

    // Check if previous list items are the same as the new list items
    if (prevChildren.current) {
      const prev = prevChildren.current
      if (prev.length === childrenArray.length) {
        let i = 0
        let same = true
        while (same && i < prev.length - 1) {
          same = prev[i] && childrenArray[i] && prev[i].key === childrenArray[i].key
          i += 1
        }
        newItems = !same
      }
    } else {
      newItems = false
    }

    // Reset focus index if the list of items updates
    if (newItems) {
      setFocusIndex(0)
    }

    prevChildren.current = childrenArray
  }, [children])

  useEffect(() => {
    // Autofocus on mount
    if (autofocus && focusableItem && focusableItem.current) {
      focusableItem.current.base.focus()
    }
  }, [])

  return (
    <Col
      component="ul"
      class="bui"
      margin="0"
      padding="0"
      props={{
        role: 'list',
        ...props
      }}
      {...style}
    >
      {items.map((item, index) => {
        const focusable = index === focusIndex
        const itemWithProps = cloneElement(item, {
          focusSelf: () => focus(index),
          focusNext,
          focusPrevious,
          ref: focusable ? focusableItem : undefined,
          listOnBlur: () => {
            setHasFocus(false)
          },
          focusable,
          roundedCorners,
          delayClick,
          selectOnSpacePressed
        })
        if (divider && index < items.length - 1) {
          return (
            <Fragment>
              {itemWithProps}
              {divider}
            </Fragment>
          )
        }
        return itemWithProps
      })}
    </Col>
  )
}

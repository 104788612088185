import { h } from 'preact'
import './ProgressCircular.css'

const ProgressCircularInderetminate = ({ size, color }) => (
  <svg
    class="bui"
    width={size}
    height={size}
    viewBox="0 0 48 48"
    stroke={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g class="spinner" stroke={color}>
      <circle class="loading" fill="none" stroke-width="4" cx="24" cy="24" r="20"></circle>
    </g>
  </svg>
)

const ProgressCircularDeterminate = ({ size, color, value = 0 }) => {
  const radius = 20
  const circumference = radius * 2 * Math.PI
  const filled = (value / 1) * circumference
  const gap = circumference - filled
  const dashArray = `${filled} ${gap}`
  return (
    <svg
      class="bui"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill="none"
        stroke={color}
        stroke-opacity="0.25"
        stroke-width="4"
        cx="24"
        cy="24"
        r={radius}
      ></circle>
      <circle
        fill="none"
        stroke={color}
        stroke-width="4"
        stroke-dasharray={`${dashArray}`}
        stroke-dashoffset="0"
        stroke-linecap="butt"
        cx="24"
        cy="24"
        r={radius}
        transform="translate(24, 24) rotate(-90) translate(-24 -24)"
      ></circle>
    </svg>
  )
}

export const ProgressCircular = ({ size = 48, color = 'var(--accent)', value }) =>
  value >= 0 ? (
    <ProgressCircularDeterminate size={size} color={color} value={value} />
  ) : (
    <ProgressCircularInderetminate size={size} color={color} />
  )

import { h } from 'preact'
import { useRef, useState, useEffect } from 'preact/hooks'
import { useMedia } from '@sodra/use-media'
import { useKeyPressedHighlight } from './use-key-pressed-highlight'
import { InlineBlock, Block } from 'jsxstyle/preact'
import { Ripples } from './Ripples'
import { Tooltip } from './Tooltip'

export const Link = ({ to, title, target, onClick, delay = false, children, props, ...style }) => {
  const linkElem = useRef()
  const [clicked, setClicked] = useState(false)
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [keyPressedHighlights, addKeyPressedHighlight] = useKeyPressedHighlight({
    color: 'white'
  })

  const supportsHover = useMedia(['(hover: hover)'], [true], false)
  const clickable = to || onClick

  useEffect(() => {
    if (clicked) {
      const timeout = setTimeout(() => setClicked(false), 300)
      return () => clearTimeout(timeout)
    }
  }, [clicked])

  const handleClick = (e) => {
    if (onClick) {
      e.stopPropagation()
      e.preventDefault()
      e.stopPropagation()
    }
    setClicked(true)
    if (onClick) {
      setTimeout(
        () => {
          onClick({ type: 'pointer', x: e.clientX, y: e.clientY, target: e.target })
        },
        delay ? 180 : 0
      )
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault()
      addKeyPressedHighlight()
      onClick && onClick({ type: 'keyboard', target: e.target })
    }
  }

  const handleMouseOver = (e) => setHover(true)

  const handleMouseOut = (e) => setHover(false)

  const handleFocus = (e) => setFocus(true)

  const handleBlur = (e) => setFocus(false)

  return (
    <InlineBlock
      class="bui-show-keyboard-focus"
      component="a"
      position="relative"
      cursor="pointer"
      textDecoration={hover ? 'underline' : 'none'}
      props={{
        ref: linkElem,
        href: to ? to : '',
        rel: 'noopener',
        title: title,
        target: target,
        onClick: handleClick,
        onKeyDown: clickable ? handleKeyDown : null,
        onMouseOver: clickable && supportsHover ? handleMouseOver : null,
        onMouseOut: clickable && supportsHover ? handleMouseOut : null,
        onFocus: clickable ? handleFocus : null,
        onBlur: clickable ? handleBlur : null,
        ...props
      }}
      {...style}
    >
      {children}
      <Ripples disabled={!clickable} />
      {keyPressedHighlights}
      {title && <Tooltip visible={focus || hover} generator={linkElem} text={title} />}
    </InlineBlock>
  )
}

export default Link

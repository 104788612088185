import { h, Fragment } from 'preact'
import { useRef, useState, useEffect, useMemo } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'
import { Button } from './Button'
import { CloseIcon } from './icons'
import { Divider } from './dividers'
import { IconButton } from './IconButton'
import { H6 } from './texts'
import { SpacerVertical } from './spacers'
import { getUniqueId } from './get-unique-id'

const Action = ({
  id,
  text,
  tooltipText,
  icon,
  onClick,
  delayClick,
  disabled,
  loading,
  type,
  ...style
}) => {
  const contained = type === 'contained'
  const outlined = type === 'outlined'
  return (
    <Button
      props={{ id }}
      contained={contained}
      outlined={outlined}
      icon={icon}
      onClick={onClick}
      delayClick={delayClick}
      disabled={disabled}
      loading={loading}
      tooltipText={tooltipText}
      {...style}
    >
      {text}
    </Button>
  )
}

export const Dialog = ({
  titleIcon,
  onTitleIconClick,
  title,
  actions = [],
  secondaryActions = [],
  children,
  footer1,
  footer2,
  hideDialog = false,
  dismissable = true,
  onClose,
  small,
  large,
  full,
  overflow,
  flexGrowContent = false,
  disableScroll = false,
  focusOnce = false,
  disableAutofocus = false,
  ...style
}) => {
  // Keep reference to current element with focus
  const focusedElemBeforeOpen = useMemo(() => document.activeElement, [])
  const [hasFocusedOnce, setHasFocusedOnce] = useState(false)

  const supportsHover = useMedia(['(hover: hover)'], [true], false)
  const isNarrow = useMedia(['(max-width: 600px)'], [true], false)

  const dialogRef = useRef()
  const mainRef = useRef()
  const outerRef = useRef()
  const [isVisible, setIsVisible] = useState(false)
  const titleId = useMemo(() => getUniqueId('bui-dialog-title'), [])

  const [bounds, setBounds] = useState()
  const [scroll, setScroll] = useState()

  const blockRef = useRef()
  const [renderBlock, setRenderBlock] = useState(disableAutofocus)

  useEffect(() => {
    setIsVisible(true)
    if (supportsHover) {
      return () => {
        focusedElemBeforeOpen && focusedElemBeforeOpen.focus()
      }
    }
  }, [])

  useEffect(() => {
    if (blockRef.current) {
      blockRef.current.focus()
    }
  }, [blockRef.current])

  useEffect(() => {
    // Get reference to all children that can be focused
    // Remove element if style.visibility = 'hidden'
    // Remove element if style.display = 'none'
    var focusableEls = dialogRef.current.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    )
    focusableEls = Array.prototype.slice.call(focusableEls)
    focusableEls = focusableEls.filter(
      (el) => el.style.visibility !== 'hidden' && el.style.display !== 'none'
    )
    const firstFocusableEl = focusableEls ? focusableEls[0] : undefined
    const lastFocusableEl = focusableEls ? focusableEls[focusableEls.length - 1] : undefined
    const dontFocus = disableAutofocus || (focusOnce && hasFocusedOnce)
    if (supportsHover && !dontFocus) {
      // Give focus to first focusable child
      // Note: skip if any of the children already has focus
      // Note: skip if focus has already been set once
      if (!focusableEls.find((el) => el === document.activeElement)) {
        firstFocusableEl && firstFocusableEl.focus()
        setHasFocusedOnce(true)
      }
    }
    //
    const handleKeyDown = (e) => {
      // TAB
      if (e.keyCode === 9) {
        if (!firstFocusableEl && !lastFocusableEl) {
          // Close dialog on tab if dialog has no focusable children
          e.preventDefault()
          onClose()
        } else if (firstFocusableEl === lastFocusableEl) {
          // Prevent tabbing outside dialog if there is only one
          // focusable child
          e.preventDefault()
          firstFocusableEl && firstFocusableEl.focus()
        } else {
          if (e.shiftKey) {
            // Give focus to last focusable child, if
            //   Current focused elem is first focusable child
            //     or if
            //   None of the focusable children has focus
            if (
              e.target === firstFocusableEl ||
              !focusableEls.find((el) => el === document.activeElement)
            ) {
              e.preventDefault()
              lastFocusableEl && lastFocusableEl.focus()
            }
          } else {
            // Give focus to first focusable child, if
            //   Current focused elem is last focusable child
            //     or if
            //   None of the focusable children has focus
            if (
              e.target === lastFocusableEl ||
              !focusableEls.find((el) => el === document.activeElement)
            ) {
              e.preventDefault()
              firstFocusableEl && firstFocusableEl.focus()
            }
          }
        }
      }
      // ESCAPE
      if (e.keyCode === 27) {
        closeModal()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [children, dialogRef.current])

  useEffect(() => {
    if (hideDialog) {
      closeModal()
    }
  }, [hideDialog])

  const handleOuterClick = (e) => {
    if (e.target === outerRef.current) {
      closeModal()
    }
  }

  const closeModal = () => {
    setIsVisible(false)
    setTimeout(onClose, 360 + 180)
  }

  const handleResize = (_) => {
    if (mainRef.current) {
      setBounds(mainRef.current.getBoundingClientRect())
    }
  }

  const handleScroll = (e) => {
    if (mainRef.current) {
      setScroll({
        scrollTop: mainRef.current.scrollTop,
        scrollHeight: mainRef.current.scrollHeight
      })
    }
  }

  useEffect(() => {
    handleResize()
  }, [children])

  useEffect(() => {
    handleScroll()
  }, [bounds])

  useEffect(() => {
    if (mainRef.current && !disableScroll) {
      const main = mainRef.current
      const resizeObserver = new ResizeObserver((_) => {
        handleResize()
      })
      resizeObserver.observe(main)
      main.addEventListener('scroll', handleScroll)
      window.addEventListener('resize', handleResize)
      handleResize()
      handleScroll()
      return () => {
        resizeObserver.unobserve(main)
        main.removeEventListener('scroll', handleScroll)
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [mainRef.current, disableScroll])

  const scrollActive = !disableScroll && bounds && scroll && bounds.height < scroll.scrollHeight
  const showTopDivider = !disableScroll && scrollActive && scroll.scrollTop > 0
  const showBottomDivider =
    !disableScroll && scrollActive && bounds.height + scroll.scrollTop < scroll.scrollHeight
  const medium = !small && !large

  return (
    <Block
      class="bui"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top="0"
      left="0"
      bottom="0"
      right="0"
      background="var(--modal-background)"
      opacity={isVisible ? 1 : 0}
      transition={'opacity .18s cubic-bezier(0, 0, .2, 1)'}
      transitionDelay={!isVisible ? '.18s' : undefined}
      willChange="opacity"
      zIndex="4"
      props={{
        ref: outerRef,
        onClick: dismissable ? handleOuterClick : undefined
      }}
    >
      {renderBlock && (
        <Block props={{ ref: blockRef, tabIndex: 0, onBlur: () => setRenderBlock(false) }} />
      )}
      <Col
        position="relative"
        width={full ? '100%' : 'calc(100% - 100px)'}
        height={full ? '100%' : large ? 'calc(100% - 100px)' : undefined}
        minWidth={medium || large ? '240px' : undefined}
        maxWidth={full ? 'none' : large ? '800px' : medium ? '500px' : small ? '240px' : undefined}
        minHeight={medium ? '240px' : undefined}
        maxHeight={full ? 'none' : 'calc(100% - 100px)'}
        margin={full ? 0 : '30px'}
        background="var(--surface)"
        opacity={isVisible ? 1 : 0}
        transition={'opacity .18s cubic-bezier(0, 0, .2, 1)'}
        transitionDelay={isVisible ? '.18s' : undefined}
        willChange="opacity"
        boxShadow="0px 3px 12px var(--box-shadow-color)"
        borderRadius="3px"
        props={{
          ref: dialogRef,
          role: 'dialog',
          'aria-labelledby': titleId
        }}
      >
        {overflow && (
          <Block
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            borderRadius="3px"
            overflow="hidden"
          >
            {children}
          </Block>
        )}
        <Row alignItems="center" flexShrink="0" height="70px" zIndex="0">
          {full && !titleIcon && (
            <IconButton
              flexShrink="0"
              marginLeft="10px"
              onClick={onClose}
              icon={CloseIcon}
              color="var(--on-surface)"
              delayClick
            />
          )}
          {titleIcon && (
            <IconButton
              flexShrink="0"
              marginLeft="10px"
              onClick={onTitleIconClick}
              icon={titleIcon}
              color="var(--on-surface)"
              delayClick
            />
          )}
          <Block padding={isNarrow ? '0 20px' : '0 30px'}>
            <H6
              props={{
                id: titleId
              }}
            >
              {title}
            </H6>
          </Block>
        </Row>
        {!overflow && (
          <Fragment>
            <Divider opacity={showTopDivider ? 1 : 0} />
            <Block
              flexShrink="1"
              flexGrow={flexGrowContent ? 1 : full ? 0 : 1}
              height={large ? '100%' : undefined}
              padding={isNarrow ? '0 20px' : '0 30px'}
              class="bui-show-scroll"
              overflowY={scrollActive ? 'scroll' : 'hidden'}
              overflowX="auto"
              props={{
                ref: mainRef
              }}
              {...style}
            >
              {children}
            </Block>
            <Divider opacity={showBottomDivider ? 1 : 0} />
          </Fragment>
        )}
        {overflow && (
          <Block
            flexShrink="1"
            flexGrow={flexGrowContent ? 1 : full ? 0 : 1}
            height={large ? '100%' : undefined}
            {...style}
          />
        )}
        {((actions && actions.length > 0) || (secondaryActions && secondaryActions.length > 0)) && (
          <Fragment>
            <Col flexShrink="0">
              <SpacerVertical small />
              <Row padding={isNarrow ? 0 : '0 10px'} flex="0" justifyContent="space-between">
                {actions && actions.length > 0 && (
                  <Row alignItems="center" flexWrap="wrap">
                    {actions.map((action, index) => {
                      const marginLeft =
                        index === 0 && action.type === 'contained' ? '20px' : undefined
                      return <Action {...action} margin="5px" marginLeft={marginLeft} />
                    })}
                  </Row>
                )}
                {secondaryActions && secondaryActions.length > 0 && (
                  <Row alignItems="center" flexWrap="wrap">
                    {secondaryActions.map((action, index) => {
                      const marginRight =
                        index === secondaryActions.length - 1 && action.type === 'outlined'
                          ? '20px'
                          : undefined
                      return <Action {...action} margin="5px" marginRight={marginRight} />
                    })}
                  </Row>
                )}
              </Row>
              <SpacerVertical small />
            </Col>
            {(footer1 || footer2) && <SpacerVertical />}
          </Fragment>
        )}
        {footer1 && (
          <Fragment>
            <Divider />
            <Block color="var(--on-surface-light)" padding={isNarrow ? '0 20px' : '0 30px'}>
              {footer1}
            </Block>
          </Fragment>
        )}
        {footer2 && (
          <Fragment>
            <Divider />
            <Block color="var(--on-surface-light)" padding={isNarrow ? '0 20px' : '0 30px'}>
              {footer2}
            </Block>
          </Fragment>
        )}
      </Col>
    </Block>
  )
}

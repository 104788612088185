import { h, Fragment } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { InlineBlock, Row } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'
import { getHighlightColor } from './highlight-colors'
import { Highlight } from './highlights'
import { useKeyPressedHighlight } from './use-key-pressed-highlight'
import { Ripples } from './Ripples'
import { ProgressCircular } from './ProgressCircular'
import { SpacerHorizontal } from './spacers'
import {
  getBackgroundColor,
  getColor,
  getBorderColor,
  getIconSize,
  getLoadingSize,
  getBorder,
  getFontSize,
  getLineHeight,
  getHeight,
  getPadding
} from './button-styles'
import { Tooltip } from './Tooltip'

export const Button = ({
  name = 'button',
  children,
  icon: Icon,
  onClick,
  delayClick = false,
  outlined,
  contained,
  error,
  capitalized,
  type = 'button',
  large,
  small,
  tiny,
  disabled,
  focusable = true,
  color: propColor,
  borderColor: propBorderColor,
  backgroundColor: propBackgroundColor,
  highlightColor: propHighlightColor,
  loading,
  tooltipText,
  borderRadius = '3px',
  onFocus,
  onBlur,
  props,
  fontSize,
  lineHeight,
  fontWeight,
  ...style
}) => {
  const buttonType = outlined ? 'outlined' : contained ? 'contained' : error ? 'error' : 'text'

  const buttonElem = useRef()

  const color = propColor || getColor(buttonType)
  const backgroundColor = propBackgroundColor || getBackgroundColor(buttonType)
  const highlightColor = propHighlightColor || getHighlightColor(buttonType)
  const borderColor = propBorderColor || getBorderColor(buttonType)
  const border = getBorder({ type: buttonType, color: borderColor, disabled, loading })
  const iconSize = getIconSize({ small, tiny })

  const [isUsingTouchTimeout, setIsUsingTouchTimeout] = useState(undefined)
  const [isUsingMouse, setIsUsingMouse] = useState(false)
  const [isUsingTouch, setIsUsingTouch] = useState(false)
  const [showFocus, setShowFocus] = useState(false)
  const [hover, setHover] = useState(false)
  const [keyPressedHighlights, addKeyPressedHighlight] = useKeyPressedHighlight({
    color: highlightColor,
    borderRadius
  })

  const supportsHover = useMedia(['(hover: hover)'], [true], false)
  const clickable = !disabled && !loading

  useEffect(() => {
    if (disabled) {
      setShowFocus(false)
      setHover(false)
    }
  }, [disabled])

  const handleClick = (e) => {
    e.stopPropagation()
    setTimeout(
      () => {
        if (onClick) {
          onClick({ type: 'pointer', x: e.clientX, y: e.clientY, target: e.target })
        }
      },
      delayClick ? 180 : 0
    )
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      // Key down (space/enter) on a button triggers a click event by default
      //
      // 1. Skip triggering click if onClick is set. Historical reasons.
      //
      // 2. Skip triggering click for all buttons that are not of type submit
      // Because: the click event contains incorrect mouse pointer positions
      // which will position floating sheets incorrectly (center of component)
      //
      if (onClick || type !== 'submit') {
        e.preventDefault()
        e.stopPropagation()
      }
      addKeyPressedHighlight()
      onClick && onClick({ type: 'keyboard', target: buttonElem.current })
    }
  }

  const handleMouseOver = (e) => setHover(true)

  const handleMouseOut = (e) => setHover(false)

  const handleMouseDown = (e) => setIsUsingMouse(true)

  const handleMouseUp = (e) => setIsUsingMouse(false)

  const handleTouchStart = (e) => {
    if (isUsingTouchTimeout) {
      clearTimeout(isUsingTouchTimeout)
    }
    setIsUsingTouch(true)
  }

  const handleTouchEnd = (e) => {
    if (isUsingTouchTimeout) {
      clearTimeout(isUsingTouchTimeout)
    }
    setIsUsingTouchTimeout(setTimeout(() => setIsUsingTouch(false), 300))
  }

  const handleFocus = (e) => {
    // Skip showing focus if mouse or touch is used
    if (!isUsingMouse && !isUsingTouch) {
      setShowFocus(true)
    }
    if (onFocus) {
      onFocus()
    }
  }

  const handleBlur = (e) => {
    setShowFocus(false)
    if (onBlur) {
      onBlur()
    }
  }

  useEffect(() => {
    const e = buttonElem.current
    if (clickable) {
      e.addEventListener('touchstart', handleTouchStart, { passive: true })
      e.addEventListener('touchend', handleTouchEnd, { passive: true })
    }
    return () => {
      if (clickable) {
        e.removeEventListener('touchstart', handleTouchStart)
        e.removeEventListener('touchend', handleTouchEnd)
      }
    }
  }, [])

  return (
    <InlineBlock
      class="bui-show-keyboard-focus"
      component="button"
      props={{
        ref: buttonElem,
        type,
        name,
        onClick: clickable ? handleClick : null,
        onKeyDown: clickable ? handleKeyDown : null,
        onMouseDown: clickable && supportsHover ? handleMouseDown : null,
        onMouseUp: clickable && supportsHover ? handleMouseUp : null,
        onMouseOver: clickable && supportsHover ? handleMouseOver : null,
        onMouseOut: clickable && supportsHover ? handleMouseOut : null,
        onFocus: clickable ? handleFocus : null,
        onBlur: clickable ? handleBlur : null,
        tabIndex: focusable && clickable ? 0 : -1,
        ...props
      }}
      position="relative"
      outline="none"
      userSelect="none"
      WebkitUserSelect="none"
      WebkitTapHighlightColor="rgba(0, 0, 0, 0)"
      background={!backgroundColor ? 'none' : undefined}
      backgroundColor={backgroundColor || 'none'}
      color={color}
      border={border}
      borderRadius={borderRadius}
      cursor={clickable ? 'pointer' : 'not-allowed'}
      height={getHeight({ large, small, tiny })}
      padding={getPadding({ type: buttonType, large, small, tiny })}
      {...style}
    >
      {showFocus && <Highlight borderRadius={borderRadius} color={highlightColor} />}
      {hover && <Highlight borderRadius={borderRadius} color={highlightColor} />}
      {keyPressedHighlights}
      <Ripples disabled={disabled} borderRadius={borderRadius} color={highlightColor} />
      <Row
        justifyContent="center"
        alignItems="center"
        opacity={loading ? 0 : disabled ? 0.33 : 1}
        fontSize={fontSize || getFontSize({ large, small, tiny, capitalized })}
        lineHeight={lineHeight || getLineHeight({ large, small, tiny, capitalized })}
        letterSpacing={capitalized ? '0.1em' : undefined}
        textTransform={capitalized ? 'uppercase' : undefined}
        fontWeight={fontWeight || capitalized ? '500' : undefined}
      >
        {Icon && (
          <Fragment>
            <Icon size={iconSize} flexShrink="0" fill={color} marginLeft="-2px" />
            <SpacerHorizontal tiny />
          </Fragment>
        )}
        {children}
      </Row>
      {loading && (
        <InlineBlock
          display="flex"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          alignItems="center"
          justifyContent="center"
        >
          <ProgressCircular color={color} size={getLoadingSize({ large, small, tiny })} />
        </InlineBlock>
      )}
      {tooltipText && (
        <Tooltip visible={showFocus || hover} generator={buttonElem} text={tooltipText} />
      )}
    </InlineBlock>
  )
}

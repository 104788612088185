import { h, Fragment } from 'preact'
import { useRef, useEffect, useState } from 'preact/hooks'
import { Col } from 'jsxstyle/preact'
import { SearchListItem } from './SearchListItem'
import { Divider } from './dividers'
import { getPopupPosition } from './popup-position'
import { getBounds, createBounds } from './bounds'

export const Suggest = ({
  items,
  selectedIndex,
  onSelect,
  visible = true,
  onClose,
  position: positionProps,
  positionElement
}) => {
  const suggestRef = useRef()
  const selectedItem = useRef()
  const [position, setPosition] = useState()
  const [width, setWidth] = useState()

  useEffect(() => {
    window.addEventListener('click', onClose)
    return () => {
      window.removeEventListener('click', onClose)
    }
  }, [])

  const getPosition = () => {
    let placements
    let generatorBounds
    if (positionProps) {
      generatorBounds = createBounds({
        x: positionProps.x,
        y: positionProps.y,
        width: 0,
        height: 20
      })
      placements = ['bottom-align-left', 'top-align-left', 'bottom-align-right', 'top-align-right']
      setWidth(undefined)
    } else if (positionElement) {
      generatorBounds = getBounds(positionElement)
      placements = ['bottom-center', 'top-center']
      setWidth(`${generatorBounds.width}px`)
    } else {
      throw new Error('Unable to position Suggest. position or positionElement must be set.')
    }

    const bounds = suggestRef.current.getBoundingClientRect()
    const pos = getPopupPosition({
      generatorBounds,
      popupBounds: bounds,
      placements,
      padding: 10,
      offset: 5
    })
    return pos
  }

  useEffect(() => {
    if (suggestRef.current) {
      setPosition(getPosition())
    }
  }, [items, positionProps, suggestRef.current])

  useEffect(() => {
    if (selectedItem.current) {
      selectedItem.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    }
  }, [selectedItem.current])

  const [hideTimeout, setHideTimeout] = useState()

  const setVisible = (visible) => {
    if (hideTimeout) {
      clearTimeout(hideTimeout)
    }
    if (!visible) {
      setHideTimeout(setTimeout(onClose, 360))
    }
  }

  const handleSelect = (item, index) => {
    onSelect(item, index)
    setVisible(false)
  }

  useEffect(() => {
    setVisible(visible)
  }, [visible])

  const top = position ? `${position.top}px` : '59px'
  const left = position ? `${position.left}px` : undefined
  const minWidth = position ? undefined : '100%'

  return (
    <Col
      position="fixed"
      zIndex="3"
      top={top}
      left={left}
      minWidth={minWidth}
      width={width}
      maxHeight="210px"
      overflowY="auto"
      background="var(--surface-alternative)"
      boxShadow="0 1px 3px var(--box-shadow-color)"
      opacity={visible && position ? '1' : '0'}
      transition="opacity .36s cubic-bezier(0, 0, 0.2, 1)"
      willChange="opacity"
      component="ul"
      class="bui bui-show-scroll"
      margin="0"
      padding="0"
      props={{
        ref: suggestRef,
        role: 'list',
        onClick: (e) => e.stopPropagation()
      }}
    >
      {items.map((item, index) => {
        return (
          <Fragment>
            {index > 0 && <Divider />}
            <SearchListItem
              {...item}
              selected={index === selectedIndex}
              onClick={() => handleSelect(item, index)}
              props={{
                ref: index === selectedIndex ? selectedItem : undefined
              }}
            />
          </Fragment>
        )
      })}
    </Col>
  )
}

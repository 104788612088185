const removeUndefined = (obj) => {
  const newObj = {}
  for (let [key, value] of Object.entries(obj)) {
    if (value !== undefined) {
      newObj[key] = value
    }
  }
  return newObj
}

const stringify = (params) => {
  return new URLSearchParams(params)
}

const doFetch = async (method, endpoint, body, params) => {
  let url = process.env.API_URL + '/v1' + endpoint
  if (params) {
    url = url + '?' + stringify(removeUndefined(params))
  }
  const res = await fetch(url, {
    method: method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: body ? JSON.stringify(removeUndefined(body)) : null
  })

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.error)
  }

  return json
}

export const get = (url, params) => doFetch('GET', url, null, params)
export const post = (url, data) => doFetch('POST', url, data)
export const patch = (url, data) => doFetch('PATCH', url, data)
export const put = (url, data) => doFetch('PUT', url, data)
export const del = (url, data) => doFetch('DELETE', url, data)

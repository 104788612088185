import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'

import { Select, SpacerHorizontal } from '@sodra/bongo-ui'

import daniel from './daniel.png'
import jacobellis from './jacobellis.jpeg'
import harlaut from './harlaut.jpeg'
import ingemar from './ingemar.jpeg'

import { ImageCropper } from '@sodra/image-cropper'

const imageOptions = [
  { value: daniel, text: 'Daniel' },
  { value: ingemar, text: 'Ingemar' },
  { value: jacobellis, text: 'Jacobellis' },
  { value: harlaut, text: 'Harlaut' }
]

export const Test2 = () => {
  const [image, setImage] = useState(daniel)
  const [crop, setCrop] = useState()

  const handleCropChange = (crop) => {
    setCrop(crop)
  }

  return (
    <Col height="100%">
      <Row margin="20px" alignItems="center">
        <Select
          label="Image"
          options={imageOptions}
          value={image}
          onChange={setImage}
          minWidth="150px"
        />
        <SpacerHorizontal />
        <Block>
          {crop
            ? `minX: ${crop.minX}, minY: ${crop.minY}, maxX: ${crop.maxX}, maxY: ${crop.maxY}`
            : '–'}
        </Block>
      </Row>
      <ImageCropper
        url={image}
        aspectRatio={1}
        useCircularMask
        margin={50}
        onChange={handleCropChange}
        flex="1"
      />
    </Col>
  )
}
